import React from 'react'
import { useDispatch } from 'react-redux';
import burgerSVG from '../../../../resources/images/svgs/hamburger.svg';
import { show } from 'redux/features/ui/uiSlice'

const Burger = () => {
  const dispatch = useDispatch()
  return (
    <div className='burger ms-3'>
      <div role="button" onClick={()=>dispatch(show())} className="nav-burger my-3">
        <img src={burgerSVG} className="img-fluid" alt="" />
      </div>
    </div>
  )
}

export default Burger