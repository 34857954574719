import { PreFooter } from 'components/_ui/prefooter'
import React from 'react'
import { useLocation } from 'react-router-dom';
import {FooterRed} from './FooterRed.js';

export const Footer = () => {
      const location = useLocation();
      const route = location.pathname;
     
      return (
            route !== "/" && <div className="w-100 pt-0 pt-md-5">
                  <div className="container-lg d-flex justify-content-center flex-column px-4">
                        <PreFooter />
                        <div className="border-bottom w-100 mt-4"></div>
                        <div className="text-center w-100 py-5 mb-5">
                              <p className="text-center text-white fs-100">©Diesel Tech Service, LLC. All Rights Reserved.</p>
                        </div>
                  </div>
                 <FooterRed/>
            </div>
      )
}
