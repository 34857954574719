import React from 'react'
import contactIcon from 'resources/images/svgs/contact.svg';
import contactInvere  from 'resources/images/svgs/contact-inverse.svg';
import { trackOnClick } from 'utils';
export const Contact = (props) => {
  const {inverse, ...restOfProps} = props;
  return (
    <div className='contact d-flex align-items-center me-2 my-2' {...restOfProps}>
      <div className="img-wrap">
        <img src={inverse ?contactInvere: contactIcon} alt="contact icon" />
      </div>
      <div className="text ms-2">
        <h2 className={`phonenumber text-roboto-condensed fw-bold fst-italic mb-0 text-nowrap  `}> 
        <a href="tel:787-679-2526" onClick={()=>trackOnClick()} className={`text-decoration-none ${inverse && 'text-default'}` } > 787-679-2526</a>
        </h2>
      </div>
    </div>
  )
}

