import React from 'react'
import { useDispatch } from 'react-redux';
import { HashLink as Link } from "react-router-hash-link";
import { hide } from 'redux/features/ui/uiSlice';
export const MenuLink = (props) => {
    const dispatch = useDispatch()
    const {link = "#", className, label, children,  ...restOfProps} = props;

    const handleClick = () => {
      dispatch(hide());
    }
  return (
    <Link onClick={()=>handleClick()} className={`text-decoration-none fs-4 text-center fw-700 d-block p-2 ${className}`} {...restOfProps} to={link}>{label}</Link>
  )
} 