import React from 'react'
import dtsLogo from 'resources/images/logo/logo-with-text-horizontal.svg'
import dieselTechServiceLlcLogo from 'resources/images/logo/diesel-tech-service-llc-logo.svg'

const Logo = (props) => {
  const {logo = dtsLogo} = props;
  return (
    <div className='dts-logo'>
      <img src={logo} alt="dts logo with text" className='img-fluid d-none d-lg-block d-xl-block d-xxl-block'/>
      <img src={dieselTechServiceLlcLogo} alt="dts logo without text" className='img-fluid d-block d-lg-none d-xl-none d-xxl-none' />
    </div>
  )
}

export default Logo