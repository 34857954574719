import React from 'react'
import '../resources/styles/app.scss';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";

import 'resources/styles/app.scss';

// import { Home } from 'pages/Home';
import { Header } from 'components/_common';
import { Footer } from 'components/_common/footer';
import { UnderDev } from 'pages/Underdev/UnderDev';
export const App = () => {
  

  return (
    <>
    <BrowserRouter classNames="bg-dark">
      <Header/>
        <Routes>
          <Route path="/" element={<UnderDev/>}/>
          <Route path="/home" element={<UnderDev />}/>
        </Routes>
      <Footer />
    </BrowserRouter>
    </>
  )
}

export default App;
