import { createSlice } from '@reduxjs/toolkit'

let opt = {
    name: 'ui',
    initialState: {
        showOffCanvas: false,
        showHomePopUp: false
    }
}

export const uiSlice = createSlice({...opt ,
  reducers: {
    show: state => {
  
     state.showOffCanvas = true
    },
    hide: state => {
        state.showOffCanvas = false
    },
    toggle: (state, second) => {
      state.showOffCanvas = !second.payload
    },

    showHomePopUp: state => {
      state.showHomePopUp = true
    },
    hideHomePopUp: state => {
      state.showHomePopUp = false
    },
  }
})

// Action creators are generated for each case reducer function
export const { show, hide,  toggle, showHomePopUp, hideHomePopUp} = uiSlice.actions

export default uiSlice.reducer