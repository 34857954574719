import React, { useState } from 'react'

import { NavBar } from 'components/_common/navbar'
// import { Ribbon } from 'components/_ui/ribbon'
import { Waypoint } from 'react-waypoint'
import dieselTechServiceLlcLogo from 'resources/images/logo/diesel-tech-service-llc-logo.svg'
import { useLocation } from 'react-router-dom'
import { NavOffCanvas } from '../navbar/NavOffCanvas/NavOffCanvas'
// import { FlyersNavOffCanvas } from '../navbar/NavOffCanvas'

export const Header = () => {

  const location = useLocation();
  const route = location.pathname.split('/');

  console.log('location', location)

  // Handle fixed navbar on scroll
  const [showFixedNavbar, setShowFixedNavbar] = useState(false);

  const handleOnLeave = () => {
    setShowFixedNavbar(true)
  }
  const handleOnEnter = () => {
    setShowFixedNavbar(false)
  }

  const navbarClassName = () => {
    let className = "";

    if(route[1] !== "") {
      className = 'py-1 primary-navbar';
    } else {
      className = 'pt-3 normal';
    }

    return className;
  }

  // Navbar that appears on vertical scrolling
  const scrollableNavbar = () => {
    let component = '';

    if (route[1] !== '') {
      component = <NavBar logo={dieselTechServiceLlcLogo} offCanvas={<NavOffCanvas/>} socialMedia={false} contact={false}/>;
      
    } else {
      component = <NavBar logo={dieselTechServiceLlcLogo} offCanvas={<NavOffCanvas/>}/>;
    }
  
    return <div className="w-100 d-flex justify-content-center align-items-center position-fixed top-0 bg-frost-dark px-4">
      {component}
    </div>
  }
  

  return (
    route[1] === '/' && <div className='header'>
        <div className={'w-100 px-4 ' + navbarClassName()}>
          {route[1] !== '' ?  
            <NavBar logo={dieselTechServiceLlcLogo} offCanvas={<NavOffCanvas/>} socialMedia={false} contact={false}/>:
            <NavBar offCanvas={<NavOffCanvas/>}/>
            }
        </div>

        {showFixedNavbar && scrollableNavbar()}
        <Waypoint onEnter={()=>handleOnEnter()} onLeave={()=>handleOnLeave()}/>
    </div>
  )
}

