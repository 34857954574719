import { Contact } from 'components/_common'
import { SocialMedia } from 'components/_common/social-media'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { scrollWithOffset } from 'utils/utils'
import { MenuLink } from './MenuLink'
import Arrow from "resources/images/svgs/offcanvas-arrow.svg";
import { toggle } from 'redux/features/ui/uiSlice'
// import { linkList } from './menu-anchors'

export const Menu = (props) => {
    const {menuList = []} = props;
    const getCanvas = useSelector(state => state.ui.showOffCanvas);
    const dispatch = useDispatch();

    

    return ( <div className='menu text-center'>
            { menuList.length > 0 && menuList.map((link, id) => <div key={id} className='link-block d-block'>
                <MenuLink label={link.label} link={link.path} scroll={scrollWithOffset}/>
            </div>
            )}
            <div className="contact-details mt-5">
                <div className="social d-flex justify-content-center">
                    <SocialMedia className="hide-after" inverse={true}/>
                </div>
                <div className="social d-flex justify-content-center">
                    <Contact inverse={true}/>
                </div>
                <div className="social d-flex justify-content-center mt-2">
                   <h6 className='text-default text-small'>©Diesel Tech Service, LLC. All Rights Reserved.</h6>
                </div>

                <div role="button" onClick={()=>dispatch(toggle(getCanvas))} className="closeOpen bg-white px-3 py-3">
                    <div className={`arrow-wrap ${getCanvas && 'flip-horizontal'}`}>
                        <img src={Arrow} alt="arrow" className='img-fluid' />
                    </div>
                </div>
                
            </div>
        </div>
    )
}
