import React  from 'react'
import facebookSVG from 'resources/images/svgs/facebook.svg';
import facebookSVGInversed from 'resources/images/svgs/facebook-inverse.svg';
import instagramSVG from 'resources/images/svgs/instagram.svg';
import instagramSVGInversed from 'resources/images/svgs/instagram-inverse.svg';
import linkedinSVG from 'resources/images/svgs/linkedin.svg';
import linkedinSVGInversed from 'resources/images/svgs/linkedin-inverse.svg';

export const SocialMedia = (props) => {
  const {className, inverse = false,  ...restOfProps} = props;
  return (
    <div className={`social-media d-flex justify-content-center align-items-center my-2 ${className}`} {...restOfProps}>
      <div className="img-wrap me-2">
        <a href="https://web.facebook.com/dieseltechpr" target="_blank" rel="noreferrer"><img src={inverse ? facebookSVGInversed : facebookSVG} className="img-fluid" alt="facebook" /></a>
      </div>
      <div className="img-wrap me-2 ">
        <a href="https://www.instagram.com/dieseltechservicepr/" target="_blank" rel="noreferrer"><img src={inverse ? instagramSVGInversed : instagramSVG} className="img-fluid" alt="instagram" /></a>
      </div>
      <div className="img-wrap">
        <a href="https://www.linkedin.com/company/dieseltechpr" target="_blank" rel="noreferrer"> <img src={inverse ? linkedinSVGInversed : linkedinSVG} className="img-fluid"  alt="linkedin" /></a>
      </div>
    </div>
  )
}

