import { OffCanvas } from 'components/_common/off-canvas'
import { Menu } from 'components/_ui/menu'
import { linkList } from 'configs/menuLinks'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { hide } from 'redux/features/ui/uiSlice'

export const NavOffCanvas = () => {
    const dispatch = useDispatch();
    const handleClose = () => dispatch(hide())
    const show = useSelector(state => state.ui.showOffCanvas);
  return (
   <>
        <OffCanvas className={'dts-offcanvas'} showOffCanvas={show} handleCloseOffCanvas={handleClose} placement={'end'}>
            <Menu menuList={linkList} />
        </OffCanvas>
   </>
  )
}
