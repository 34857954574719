import React from 'react'

import { Offcanvas } from 'react-bootstrap'

export const OffCanvas = (props) => {
  const {title, showOffCanvas, handleCloseOffCanvas, className, children, headerClose, ...restOfProps} = props;


  return (
    <div className='menu-offcanvas'>
        <Offcanvas show={showOffCanvas} className={className} onHide={handleCloseOffCanvas} {...restOfProps}>
            <Offcanvas.Header closeButton={headerClose}>
            <Offcanvas.Title>{title}</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                {children}
            </Offcanvas.Body>
        </Offcanvas>
    </div>
  )
}
