import { Contact } from 'components/_common';
import { SocialMedia } from 'components/_common/social-media';
import React from 'react'
import dts from 'resources/images/logo/diesel-tech-service-llc-logo.svg';
import dieselalysis from 'resources/images/logo/dieselalysis-logo.svg';
import dieselJunkie from 'resources/images/logo/diesel-junkie-pr-logo.svg';

export const PreFooter = () => {
  return (
    <div className="pre-footer container-lg p-4 d-flex align-items-center justify-content-center flex-column flex-md-row">
      <div className="left dts-services-logos  d-flex w-auto">
        <div className="img-wrap w-auto mx-2">
          <img src={dts} className="img-fluid w-100" alt="" />
        </div>
        <div className="img-wrap w-auto mx-2">
          <img src={dieselalysis} className="img-fluid w-100" alt="" />
        </div>
        <div className="img-wrap w-auto mx-2">
          <img src={dieselJunkie} className="img-fluid w-100" alt="" />
        </div>
      </div>
      <div className="right ps-0 ps-md-5 d-flex flex-column w-auto mt-4 mt-md-0">
        <div className="w-100 d-flex flex-row-reverse align-items-start flex-md-column ">
          <Contact/>
          <SocialMedia className="hide-after-on-lg"/>
        </div>
       
      </div>
    </div>
  )
}