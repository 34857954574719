import React from 'react'
import NFT from 'resources/images/VECTORS/nft.svg';
import zholiday from 'resources/images/VECTORS/zombie-holiday-nft.png';
import discord from 'resources/images/VECTORS/discord.svg';
import twitter from 'resources/images/VECTORS/twitter.svg';
import insta from 'resources/images/VECTORS/insta.svg';
import martleycore from 'resources/images/VECTORS/martleycore.svg';
import mbd from 'resources/images/VECTORS/mbd.svg';


export const UnderDev = () => {
  return (
    <div id="zh" className='underDev text-white'>

      {/* header */}
      <div id="nft-green" className="container-lg">
        <div className="img-wrap d-flex justify-content-center align-items-center">
          <img src={NFT} alt="" />
        </div>
      </div>

      <div id="zh-biglogo" className="w-100 ">
        <div className="container-lg ">
          <div className="zh-wrap d-flex align-items-center justify-content-center">
            <img src={zholiday} className="img-fluid" alt="" />
          </div>
        </div>
      </div>

      <div id="zh-title" className='w-100'>
        <div className="container-lg">
          <div className="title-wrap">
            <h1 className='text-open-sans fw-700 text-center'>Hang on for a bit,<br />just rigging up.</h1>
          </div>
        </div>
      </div>

      <div id="hide-out-invite" className='w-100'>
        <div className="container-lg d-flex align-items-start justify-content-center">
          <div className="discord-icon">
            <img src={discord} className="img-fluid" alt="" />
          </div>
          <div className="invitation-text ps-3 ps-md-4">
            <h4 className='mb-0 text-roboto fw-300'>For now, get into our Hideout... </h4>
            <h4 className='mb-0 text-roboto fw-300'>Jim knows when to attack!</h4>
          </div>
        </div>
      </div>

      <div id="join-hideout" className="w-100">
        <div className="container-lg d-flex align-items-center justify-content-center">
          <a role="button" href="https://discord.com/invite/nvTBbyfSbz" target='_blank' rel="noreferrer" className="btn btn-primary fw-700 text-open-sans d-flex justify-content-center align-items-center">JOIN HIDEOUT</a>
        </div>
        <div className="get-updates container-lg text-center">
          <p className='text-small fw-400'>Get exclusive updates, ‘till we release the zombies, and perks.</p>
        </div>
      </div>

      <div id="footer" className="w-100 pb-5">
        <div className="container-lg">
          <div className="top d-flex align-items-center justify-content-center">
            <div className="text-wrap d-flex align-items-center">
              <p className='mb-0 text-open-sans fw-700 me-2'>CONNECT</p>
            </div>
            <div className="icon-wrap d-flex align-items-center justify-content-center">
              <a href="https://twitter.com/zombieholidayx" target="_blank" rel="noreferrer"><img src={twitter} className="img-fluid twitter" alt="zombie holiday twitter" /></a>
              <a href="https://www.instagram.com/zombieholidayx/" target="_blank" rel="noreferrer"><img src={insta} className="img-fluid insta" alt="zombie holiday instagram" /></a>
            </div>
          </div>
          <div className="bottom d-flex flex-column flex-md-row align-items-center justify-content-center">
            <div className="mbd-icons  d-flex align-items-center justify-content-center">
            <a href="https://martleybizdesign.com/" target="_blank" rel="noreferrer"><img src={martleycore} alt="" className=" martleycore img-fluid me-2" /></a>
             <a href="https://martleybizdesign.com/" target="_blank" rel="noreferrer"><img src={mbd} alt="" className="mbd img-fluid me-2" /></a> 
            </div>
            <div className="text-wrap text-center mt-2 mt-md-0">
              <a href="https://martleybizdesign.com/" className='text-decoration-none text-white' target="_blank" rel="noreferrer"><p className='mb-0 text-open-sans'>Zombie Holiday is an NFT project, created by <span className='text-primary-light'> Martley™ </span></p></a>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
