import React from 'react'

import { Burger } from './burger'
import { Contact } from 'components/_common'
import { Logo } from './logo'
import { SocialMedia } from '../social-media'

export const NavBar = (props) => {
  const {logo, socialMedia = true, contact = true, offCanvas} = props
  return (
    <>
      <div className='container-lg navbar d-flex justify-content-between align-items-center w-100 px-0 px-lg-4'>
        <div className="left">
          <a href="/"><Logo logo={logo}/></a>
        </div>
        <div className="middle d-block d-sm-none ms-2">
          {contact && <Contact/>}
        </div>
        <div className="right d-flex">
          <div className='d-none d-sm-flex'>
            {socialMedia && <SocialMedia/>}
            {contact && <Contact/>}
          </div>
          <Burger/>
        </div>
      </div>
      {offCanvas}
    </>
  )
}